'use client';
import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useGetProjectMetadataBase, useGetUser } from '@/api/graphql';
import { AuthenticationContext } from '@/common/providers/AuthenticationProvider/AuthenticationContext';
import { getElementId } from '@/common/utils/getElementId';

const Page = () => {
  const router = useRouter();
  const { data, loading: loadingUser } = useGetUser();
  const { auth0OrgId } = useContext(AuthenticationContext);

  const lastAccessedProjectId =
    data?.getUser?.userOrgData?.lastAccessedProjectId;

  const { error, loading: loadingProject } = useGetProjectMetadataBase({
    variables: {
      id: lastAccessedProjectId!,
    },
    skip: !lastAccessedProjectId,
  });

  const isLoading = loadingUser || (!!lastAccessedProjectId && loadingProject);

  useEffect(() => {
    if (!isLoading) {
      if (lastAccessedProjectId && !error) {
        // Redirect the user to the last opened project
        router.push(
          `/organizations/${auth0OrgId}/projects/${getElementId(lastAccessedProjectId)}/overview`
        );
      } else {
        router.push(`/organizations/${auth0OrgId}/overview`);
      }
    }
  }, [auth0OrgId, error, isLoading, lastAccessedProjectId, router]);

  return <></>;
};

export default Page;
